<template>
  <div id="NotFound">
    <header class="imgination">
      <img src="logo_border.png" />
    </header>
    <SiteNav></SiteNav>

    <div class="container text-center">
        <p class="lead">Hast Du Dich verlaufen?</p>
        <p>Die Seite wurde leider nicht gefunden.</p>
        <p><a href="/">Zur Startseite</a></p>

    </div>
  </div>
</template>

<script>


import SiteNav from "@/components/TheNavigation";

export default {
  name: "NotFound",
  components: {
    SiteNav
  },
  data: () => {
    return {
        
    };
  },
  methods: {
    
  },
  mounted() {
  },
  computed: {
    
  },
};
</script>

<style scoped>
</style>
